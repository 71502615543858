// Utils
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import {
  addressyApi,
  authApi,
  lineupApi,
  lineupTransactionalApi
} from 'services'
import { rtkQueryErrorLogger } from 'services/middleware/rtkQueryErrorLogger'
import authReducer from '../features/auth/authSlice'

export const store = configureStore({
  reducer: {
    [lineupApi.reducerPath]: lineupApi.reducer,
    [lineupTransactionalApi.reducerPath]: lineupTransactionalApi.reducer,
    [addressyApi.reducerPath]: addressyApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(lineupApi.middleware)
      .concat(lineupTransactionalApi.middleware)
      .concat(authApi.middleware)
      .concat(rtkQueryErrorLogger)
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
