import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'app/store'
import {
  Auth,
  MFAauth,
  AuthToken,
  MFAChallenge,
  MFAChallengeResponse
} from 'shared/interfaces'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LINE_UP_AUTH_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: [],
  endpoints: builder => ({
    /*  login: builder.mutation<AuthToken, Auth | MFAauth>({
      query: credentials => {
        return {
          url: '/token/',
          method: 'POST',
          body: credentials
        }
      }
    }), */
    login: builder.mutation<AuthToken, Auth | MFAauth>({
      query: credentials => {
        return {
          url: '/device/token/',
          method: 'POST',
          body: credentials
        }
      }
    }),
    mfaCreate: builder.mutation<MFAChallengeResponse, MFAChallenge>({
      query: challengeRequest => {
        return {
          url: '/mfa/challenge/',
          method: 'POST',
          body: challengeRequest
        }
      }
    }),
    switch: builder.mutation<
      { access_token: string; transactional_token: string; token_type: string },
      string
    >({
      query: orgId => `switch/${orgId}/`
    }),
    forgotPassword: builder.mutation<undefined, { emailAddress: string }>({
      query: ({ emailAddress }) => ({
        url: `/forgot-password/`,
        method: 'POST',
        body: { emailAddress }
      })
    }),
    resetPassword: builder.mutation<
      undefined,
      { token: string; password: string }
    >({
      query: ({ token, password }) => ({
        url: `/reset-password/`,
        method: 'POST',
        body: { token, password }
      })
    })
  })
})

export const {
  useLoginMutation,
  useMfaCreateMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useSwitchMutation
} = authApi
