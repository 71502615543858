// Components
import { Box, Button, FormGroup, TextInput } from '@line-up/line-up-props'
// Utils
import { useEffect, useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'

type ScannerDeviceFormProps = {
  onSubmit: (data: { code: string }) => void
}

const ScannerDeviceForm = ({ onSubmit }: ScannerDeviceFormProps) => {
  const navigate = useNavigate()
  // State
  const [textInput, setTextInput] = useState<string>()

  // Handlers
  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setTextInput(e.target.value)
  }

  useEffect(() => {
    const textInputRef = document.querySelector(
      "[name='code']"
    ) as HTMLInputElement
    textInputRef.focus()
  })

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      const barcodeMinLength = 2
      if (
        textInput !== undefined &&
        textInput !== null &&
        textInput &&
        textInput.length > barcodeMinLength
      )
        navigate(`/scanner/${textInput}`)
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [navigate, textInput])

  return (
    <Box fill='horizontal'>
      <FormGroup>
        <TextInput
          autoFocus
          name='code'
          placeholder='Your Barcode'
          value={textInput}
          onChange={handleTextInputChange}
          size='large'
        />
        <Box margin={{ top: 'small' }} gap='small'>
          <Button primary size='large' type='submit' label='Submit' />
        </Box>
      </FormGroup>
    </Box>
  )
}
export { ScannerDeviceForm }
