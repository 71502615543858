// Components
import { Helmet } from 'react-helmet-async'

const LineUpHelmet = ({ title, description }) => {
  return (
    <Helmet>
      <html lang='en' />
      <title>{`${title} | Line-Up`}</title>
      <meta name='description' content={description} />

      <link
        rel='icon'
        type='image/png'
        href='/img/favicon-32x32.png'
        sizes='32x32'
      />
      <link
        rel='icon'
        type='image/png'
        href='/img/favicon-16x16.png'
        sizes='16x16'
      />

      <link rel='mask-icon' href='/img/safari-pinned-tab.svg' color='#fff' />
      <meta name='theme-color' content='#fff' />

      <meta property='og:type' content='business.business' />
      <meta property='og:title' content='Line-Up' />
      <meta property='og:url' content='/' />
      <meta property='og:image' content='/img/og-image.jpg' />
    </Helmet>
  )
}

export { LineUpHelmet }
