// Components
import { IntlProvider } from 'react-intl'
import { HelmetProvider } from 'react-helmet-async'
// Containers
import { AppContainer } from 'containers/AppContainer'
// Utils
import { BrowserRouter as Router } from 'react-router-dom'

const App = () => (
  <HelmetProvider>
    <IntlProvider locale={'en-US'}>
      <Router>
        <AppContainer />
      </Router>
    </IntlProvider>
  </HelmetProvider>
)

export default App
