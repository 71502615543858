import { useEffect } from 'react'

// Services
import { useLoginMutation } from 'services'
import { setCredentials } from 'features/auth/authSlice'

// Utils
import { useAppDispatch } from 'app/hooks'
import { useNavigate } from 'react-router-dom'

// Types
import { LoginFormValues } from 'components'

const useLogin = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [login, { data, isSuccess, error, isLoading }] = useLoginMutation()

  const handleLoginSubmit = async (auth: LoginFormValues) => {
    return await login({
      ...auth,
      grant_type: 'password'
    })
  }

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setCredentials({ token: data.access_token }))
      navigate(`/`)
    }
  }, [isSuccess, data, dispatch, navigate])

  return {
    onLoginSubmit: handleLoginSubmit,
    loginError: error,
    loginLoading: isLoading
  }
}

export { useLogin }
