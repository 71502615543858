import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware
} from '@reduxjs/toolkit'
import { setCredentials } from 'features/auth/authSlice'

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      // If 401,clear token which redirects to login
      if (action.payload.status === 401) {
        next(setCredentials({ token: null }))
      }
    }

    return next(action)
  }

export { rtkQueryErrorLogger }
