// Components
import {
  TextInputFormField,
  Form,
  Box,
  Button,
  Text,
  FormGroup
} from '@line-up/line-up-props'
// Utils
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export interface LoginFormValues {
  username: string
  password: string
}

const validationSchema = yup.object({
  username: yup.string().required('This field is required'),
  password: yup.string().required('This field is required')
})

type LoginFormProps = {
  onSubmit: (data: LoginFormValues) => void
  error?: any
  isSubmitting?: boolean
}

const LoginForm = ({ onSubmit, isSubmitting, error }: LoginFormProps) => {
  // Handlers
  const handleSubmit = (data: LoginFormValues) => {
    onSubmit(data)
  }

  return (
    <Form<LoginFormValues>
      onSubmit={handleSubmit}
      formOptions={{
        resolver: yupResolver(validationSchema)
      }}
    >
      {methods => {
        return (
          <Box fill='horizontal'>
            <FormGroup>
              <TextInputFormField
                name='username'
                placeholder='Your device name'
                control={methods.control}
                fieldProps={{
                  size: 'large'
                }}
                formFieldProps={{
                  label: [<Text size='large'>Device Name</Text>]
                }}
              />
              <TextInputFormField
                fieldProps={{
                  size: 'large',
                  type: 'password'
                }}
                name='password'
                placeholder='Your device password'
                formFieldProps={{
                  label: [<Text size='large'>Device Password</Text>]
                }}
                control={methods.control}
              />
              <Box margin={{ top: 'small' }} gap='small'>
                {error && error.data && error.data.detail && (
                  <Box align='center'>
                    <Text color='red'>{error.data.detail}</Text>
                  </Box>
                )}
                <Button
                  primary
                  size='large'
                  type='submit'
                  label='Login'
                  loading={isSubmitting}
                />
              </Box>
            </FormGroup>
          </Box>
        )
      }}
    </Form>
  )
}
export { LoginForm }
