import { lineupApi } from '../line-up'
import {
  ListResponse,
  Organisation,
  OrganisationCreate,
  OrganisationWithPermissions,
  Response
} from 'shared/interfaces'

const organisationApi = lineupApi.injectEndpoints({
  endpoints: builder => ({
    getSuperUserOrganisations: builder.query<
      ListResponse<Organisation>,
      { page: number | void; size: number | void }
    >({
      query: ({ page = 1, size = 10 }) =>
        `super-user/organisation/?page=${page}&size=${size}`,
      providesTags: ['Organisation']
    }),
    getSuperUserOrganisationById: builder.query<Response<Organisation>, number>(
      {
        query: organisationId => `super-user/organisation/${organisationId}/`,
        providesTags: (result, error, id) => [{ type: 'Organisation', id }]
      }
    ),
    createSuperUserOrganisation: builder.mutation<
      Response<Organisation>,
      OrganisationCreate
    >({
      query: organisation => ({
        url: `super-user/organisation/`,
        body: organisation,
        method: 'POST'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    editSuperUserOrganisation: builder.mutation<
      Response<Organisation>,
      { organisationId: number; organisation: OrganisationCreate }
    >({
      query: ({ organisationId, organisation }) => ({
        url: `super-user/organisation/${organisationId}/`,
        body: organisation,
        method: 'PUT'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    deleteSuperUserOrganisation: builder.mutation<
      Response<Organisation>,
      number
    >({
      query: organisationId => ({
        url: `super-user/organisation/${organisationId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    getOrganisation: builder.query<Response<Organisation>, number>({
      query: organisationId => `organisation/${organisationId}/`,
      providesTags: () => [{ type: 'Organisation' }]
    }),
    getUserOrganisations: builder.query<
      ListResponse<OrganisationWithPermissions>,
      {
        page?: number
        size?: number
      }
    >({
      query: ({ page, size }) => ({
        url: `organisation/`,
        params: {
          page,
          size
        }
      }),
      providesTags: () => [{ type: 'Organisation' }]
    }),
    createOrganisation: builder.mutation<
      Response<Organisation>,
      OrganisationCreate
    >({
      query: organisation => ({
        url: `organisation/`,
        body: organisation,
        method: 'POST'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    editOrganisation: builder.mutation<
      Response<Organisation>,
      { organisation: OrganisationCreate; organisationId: number }
    >({
      query: ({ organisation, organisationId }) => ({
        url: `organisation/${organisationId}/`,
        body: organisation,
        method: 'PUT'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    deleteOrganisation: builder.mutation<Response<Organisation>, number>({
      query: organisationId => ({
        url: `organisation/${organisationId}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Organisation', 'User']
    }),
    editOrganisationDefaults: builder.mutation<
      Response<Organisation>,
      { organisation: OrganisationCreate; organisationId: number }
    >({
      query: ({ organisation, organisationId }) => ({
        url: `organisation/${organisationId}/defaults/`,
        body: organisation,
        method: 'PUT'
      }),
      invalidatesTags: ['Organisation', 'EmailTemplate', 'TicketTemplate']
    })
  })
})

export const {
  useCreateSuperUserOrganisationMutation,
  useDeleteSuperUserOrganisationMutation,
  useEditSuperUserOrganisationMutation,
  useGetSuperUserOrganisationByIdQuery,
  useGetSuperUserOrganisationsQuery,
  useGetOrganisationQuery,
  useCreateOrganisationMutation,
  useEditOrganisationMutation,
  useDeleteOrganisationMutation,
  useEditOrganisationDefaultsMutation,
  useGetUserOrganisationsQuery,
  useLazyGetUserOrganisationsQuery
} = organisationApi
