// Components
import {
  ScannerResult,
  Scanner,
  ScannerManual,
  ScannerDevice
} from 'containers'
import { Routes, Route } from 'react-router-dom'

const ScannerView = () => (
  <Routes>
    <Route path='/device' element={<ScannerDevice />} />
    <Route path='/manual' element={<ScannerManual />} />
    <Route path=':barcode' element={<ScannerResult />} />
    <Route path='*' element={<Scanner />} />
  </Routes>
)

export { ScannerView }
