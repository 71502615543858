import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const addressyApi = createApi({
  reducerPath: 'addressyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LOQATE_API_BASE_URL
  }),
  tagTypes: ['Locations'],
  endpoints: builder => ({
    getAddresses: builder.query<any, string>({
      query: query =>
        `/Find/v1.10/json3.ws?Key=${process.env.REACT_APP_LOQATE_API_KEY}&Language=en&Text=${query}`,
      providesTags: ['Locations']
    })
  })
})

export const { useGetAddressesQuery } = addressyApi
