import { ReactNode, useContext } from 'react'
// Components
import {
  Box,
  Button,
  Header,
  HeaderProps,
  AnchorLink,
  Image
} from '@line-up/line-up-props'
import { Camera, Scan } from 'grommet-icons'
import { Logout } from 'grommet-icons'
import { Avatar, ResponsiveContext } from 'grommet'
// Utils
import { Permission } from 'shared/interfaces'
import { useNavigate, useLocation } from 'react-router-dom'
import lineupIcon from './lineup_icon.png'
import styled from 'styled-components'
import { useAppDispatch } from 'app/hooks'
// Actions
import { setCredentials, setOrganisationId } from 'features/auth/authSlice'

const Spacer = styled.div`
  flex: 1;
`

type TopBarProps = {
  selectedPermission?: Permission
  headerItems?: ReactNode
} & HeaderProps

const TopBar = (props: TopBarProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  // Handlers
  const handleQRCodeClick = () => {
    navigate('/scanner')
  }

  const handleScanCodeClick = () => {
    navigate('/scanner/device')
  }

  const handleLogoutClick = () => {
    dispatch(setCredentials({ token: null }))
    dispatch(setOrganisationId({ organisationId: null }))
  }

  const size = useContext(ResponsiveContext)

  return (
    <Header
      background='neutral-3'
      pad={{
        left: 'medium',
        right: 'medium'
      }}
      gridArea={props.gridArea}
      direction='row'
      justify='between'
      align='center'
      gap='small'
      height='52px'
      style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        zIndex: 5
      }}
    >
      <Box direction='row' align='center' gap='medium'>
        <AnchorLink to='/'>
          <Box width='30px' margin={{ right: 'small' }}>
            <Image src={lineupIcon} fit='contain' />
          </Box>
        </AnchorLink>
        {props.headerItems}
        <Spacer />
      </Box>
      <Box
        direction='row'
        align='center'
        gap={size === 'small' ? 'large' : 'medium'}
      >
        <Button onClick={handleQRCodeClick}>
          <Box
            tabIndex={-1}
            border={
              pathname === '/scanner'
                ? {
                    color: 'brand-secondary',
                    size: 'medium',
                    side: 'bottom'
                  }
                : {
                    color: 'neutral-3',
                    size: 'medium',
                    side: 'bottom'
                  }
            }
            pad={
              size === 'small'
                ? { top: '10px', bottom: '8px' }
                : { top: '9px', bottom: '9px' }
            }
          >
            {
              <Camera
                size='30px'
                color='brand-secondary'
                opacity={pathname === '/scanner' ? '1' : '0.7'}
              />
            }
          </Box>
        </Button>
        <Button onClick={handleScanCodeClick}>
          <Box
            tabIndex={-1}
            border={
              pathname === '/scanner/device'
                ? {
                    color: 'brand-secondary',
                    size: 'medium',
                    side: 'bottom'
                  }
                : {
                    color: 'neutral-3',
                    size: 'medium',
                    side: 'bottom'
                  }
            }
            pad={
              size === 'small'
                ? { top: '10px', bottom: '8px' }
                : { top: '9px', bottom: '9px' }
            }
          >
            {
              <Scan
                size='30px'
                color='brand-secondary'
                opacity={pathname === '/scanner/device' ? '1' : '0.7'}
              />
            }
          </Box>
        </Button>
        <Button onClick={handleLogoutClick}>
          <Avatar background='brand' size='2rem'>
            <Logout color='white' size='16px' />
          </Avatar>
        </Button>
      </Box>
    </Header>
  )
}

export { TopBar }
