import { lineupApi } from '../line-up'

const channelApi = lineupApi.injectEndpoints({
  endpoints: builder => ({
    loginAdmin: builder.mutation<
      {
        access_token: string
        transactional_token: string
        token_type: string
        two_factor_validated: boolean
      },
      { emailAddress: string; password: string }
    >({
      query: credentials => {
        const formData = new FormData()
        formData.append('username', credentials.emailAddress)
        formData.append('password', credentials.password)
        formData.append('scopes', 'read')
        return {
          url: 'login/',
          method: 'POST',
          body: formData
        }
      },
      invalidatesTags: ['User']
    }),
    switchAdmin: builder.mutation<
      { access_token: string; transactional_token: string; token_type: string },
      string
    >({
      query: orgId => `switch/${orgId}/`,
      invalidatesTags: ['Event']
    })
  })
})

export const { useLoginAdminMutation, useSwitchAdminMutation } = channelApi
