import { useContext } from 'react'
// Components
import { Box, Heading, ResponsiveContext } from '@line-up/line-up-props'
import {
  LineUpHelmet,
  ScannerManualForm,
  ScannerManualFormValues
} from 'components'
// Utils
import { useNavigate } from 'react-router-dom'

const ScannerManual = () => {
  const size = useContext(ResponsiveContext)
  const navigate = useNavigate()

  // Handlers
  const handleSubmit = (data: ScannerManualFormValues) => {
    navigate(`/scanner/${data.code}`)
  }

  return (
    <>
      <LineUpHelmet title='Manual Scanner' description='Manual Scanner' />
      <Box
        direction='column'
        margin={{ top: '1em' }}
        align='center'
        height='100%'
      >
        <Heading level={1}>Enter Barcode Manually</Heading>
        <Box width={size !== 'small' ? '500px' : '90%'}>
          <ScannerManualForm
            onSubmit={handleSubmit}
            error={undefined}
            loading={false}
          />
        </Box>
      </Box>
    </>
  )
}
export { ScannerManual }
