// Components
import { Box, Text } from '@line-up/line-up-props'
import { LinkNext } from 'grommet-icons'

type ScannerResultBottomBarProps = {
  onBottomBarClick: () => void
}

const ScannerResultBottomBar = ({
  onBottomBarClick
}: ScannerResultBottomBarProps) => (
  <Box
    style={{
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      right: '0px'
    }}
    height='60px'
    background='brand'
    direction='row'
    align='center'
    justify='center'
    gap='medium'
    onClick={onBottomBarClick}
  >
    <LinkNext color='white' size='large' />
    <Text color='white' weight={900}>
      Scan Next Ticket
    </Text>
  </Box>
)

export { ScannerResultBottomBar }
