// Components
import {
  TextInputFormField,
  Form,
  Box,
  Button,
  Text,
  FormGroup
} from '@line-up/line-up-props'
// Utils
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'

export interface ScannerManualFormValues {
  code: string
}

const validationSchema = yup.object({
  code: yup.string().required('This field is required')
})

type ScannerManualFormProps = {
  onSubmit: (data: ScannerManualFormValues) => void
  error?: { data?: { detail?: string } }
  loading: boolean
}

const ScannerManualForm = ({
  onSubmit,
  error,
  loading
}: ScannerManualFormProps) => {
  // Handlers
  const handleSubmit = (data: ScannerManualFormValues) => {
    onSubmit(data)
  }

  useEffect(() => {
    const textInputRef = document.querySelector(
      "[name='code']"
    ) as HTMLInputElement
    textInputRef.focus()
  })

  return (
    <Form<ScannerManualFormValues>
      onSubmit={handleSubmit}
      formOptions={{
        resolver: yupResolver(validationSchema)
      }}
    >
      {methods => {
        return (
          <Box fill='horizontal'>
            <FormGroup>
              <TextInputFormField
                name='code'
                placeholder='Enter the number beside the barcode'
                control={methods.control}
                fieldProps={{
                  size: 'large'
                }}
                formFieldProps={{
                  autoFocus: true,
                  label: [<Text size='large'>Barcode</Text>]
                }}
              />
              <Box margin={{ top: 'small' }} gap='small'>
                {error && error.data && error.data.detail && (
                  <Box align='center'>
                    <Text color='red'>{error.data.detail}</Text>
                  </Box>
                )}
                <Button
                  primary
                  size='large'
                  type='submit'
                  label='Submit'
                  disabled={loading}
                />
              </Box>
            </FormGroup>
          </Box>
        )
      }}
    </Form>
  )
}
export { ScannerManualForm }
