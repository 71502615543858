import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'app/store'

export const lineupApi = createApi({
  reducerPath: 'lineupApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LINE_UP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: [
    'Event',
    'Performance',
    'Availability Status',
    'Venue',
    'VenuePlan',
    'Customer',
    'Organisation',
    'EmailTemplate',
    'DeliveryMethod',
    'OptIn',
    'EventDeliveryMethod',
    'User',
    'TicketTemplate',
    'Discount',
    'Coupon',
    'Device',
    'Barcode',
    'Ticket'
  ],
  endpoints: builder => ({})
})
