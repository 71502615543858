// Components
import { ScannerCamera } from './ScannerCamera'
// import { ScannerTopBar } from './ScannerTopBar'
import { ScannerBottomBar } from './ScannerBottomBar'
// Utils
import { useNavigate } from 'react-router-dom'

const Scanner = () => {
  const navigate = useNavigate()

  // Handlers
  const handleScannerCameraResult = (result: string) => {
    navigate(`/scanner/${result}`)
  }
  const handleBottomBarClick = () => {
    navigate(`/scanner/manual`)
  }

  return (
    <>
      {/*       
      <ScannerTopBar venueName='Theatre Royal Drury Lane - Ready to Scan' />
       */}
      <ScannerCamera onResult={handleScannerCameraResult} />
      <ScannerBottomBar onBottomBarClick={handleBottomBarClick} />
    </>
  )
}

export { Scanner }
