import { ReactNode } from 'react'

// Utils
import { Navigate, useSearchParams } from 'react-router-dom'
import { selectCurrentToken, setCredentials } from 'features/auth/authSlice'
import { useAppDispatch, useAppSelector } from 'app/hooks'

type RequireAuthProps = {
  children: ReactNode
}

const RequireAuth = ({ children }: RequireAuthProps) => {
  const dispatch = useAppDispatch()
  const [search] = useSearchParams()

  if (search.has('token')) {
    dispatch(setCredentials({ token: search.get('token') }))
  }

  if (search.has('organisationId')) {
    localStorage.setItem('organisationId', search.get('organisationId') ?? '')
  }

  const token = useAppSelector(selectCurrentToken)

  return token ? <>{children}</> : <Navigate to='/login' />
}

export { RequireAuth }
