// Components
import { Box } from '@line-up/line-up-props'
// Utils
import { useZxing } from 'react-zxing'
import styled from 'styled-components'

const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const ScannerCamera = ({ onResult }) => {
  const { ref } = useZxing({ onResult })

  return (
    <Box height='calc(100vh - 112px)'>
      <Video ref={ref} />
    </Box>
  )
}
export { ScannerCamera }
