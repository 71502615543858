import { lineupApi } from '../line-up'
import { Response, Barcode } from 'shared/interfaces'

const barcodeApi = lineupApi.injectEndpoints({
  endpoints: builder => ({
    getBarcode: builder.query<Response<Barcode>, string>({
      query: barcode => `barcode/${barcode}/`,
      providesTags: (result, error, id) => [{ type: 'Barcode', id }]
    }),
    reedemBarcode: builder.mutation<
      Response<Barcode>,
      {
        barcode: string
        token: string
      }
    >({
      query: ({ barcode, token }) => ({
        url: `barcode/${barcode}/`,
        body: token,
        method: 'POST'
      }),
      invalidatesTags: ['Barcode']
    }),
    archiveBarcode: builder.mutation<Response<Barcode>, number>({
      query: barcode => ({
        url: `barcode/${barcode}/`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Barcode']
    })
  })
})

export const {
  useGetBarcodeQuery,
  useReedemBarcodeMutation,
  useArchiveBarcodeMutation
} = barcodeApi
