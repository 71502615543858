import { useContext } from 'react'
// Components
import {
  Box,
  AnchorLink,
  Image,
  ResponsiveContext,
  Heading
} from '@line-up/line-up-props'
import { LoginForm, LineUpHelmet } from 'components'
// Utils
import { useLogin } from 'hooks'
// Images
import logoDark from './logo.png'
import loginFooter from './heimdall_login.png'

const UserLogin = () => {
  const size = useContext(ResponsiveContext)
  const { onLoginSubmit, loginError, loginLoading } = useLogin()

  return (
    <>
      <LineUpHelmet
        title='Login'
        description='Login to Line-Up Access Control'
      />
      <Box
        direction='column'
        align='center'
        fill='horizontal'
        justify='between'
        gap='medium'
      >
        <Box
          width='100%'
          margin={{
            horizontal: 'auto',
            top: 'large',
            bottom: 'medium'
          }}
          align='center'
        >
          <AnchorLink to='/'>
            <Box width='160px' height='42px'>
              <Image src={logoDark} fit='contain' />
            </Box>
          </AnchorLink>
        </Box>

        <Box fill='horizontal' align='center'>
          <Heading margin={{ top: 'none' }}>Device Login</Heading>
          <Box align='center' width={size !== 'small' ? '500px' : '90%'}>
            <LoginForm
              onSubmit={onLoginSubmit}
              error={loginError}
              isSubmitting={loginLoading}
            />
          </Box>
        </Box>

        <Box margin={{ top: 'auto' }} width='175px'>
          <Image fill='horizontal' src={loginFooter} alt='Login to Line-Up' />
        </Box>
      </Box>
    </>
  )
}
export { UserLogin }
