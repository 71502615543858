// Components
import { RequireAuth } from 'components'
import { UserLogin, PageLayout } from 'containers'
// Views
import { ScannerView } from 'views'
// Utils
import { atlasTheme, LineUpProps } from '@line-up/line-up-props'
import { Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'
// Global styles
import './index.css'

const StyledLineUpProps = styled(LineUpProps)`
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
`

const AuthenticatedRoutes = () => (
  <RequireAuth>
    <PageLayout>
      <Routes>
        {/* Views */}
        <Route path='/' element={<Navigate replace to='scanner' />} />
        {/*         <Route path='venues/*' element={<VenueView />} />
         */}
        <Route path='scanner/*' element={<ScannerView />} />
      </Routes>
    </PageLayout>
  </RequireAuth>
)

const AppContainer = () => {
  return (
    <StyledLineUpProps id='theme-wrapper' theme={atlasTheme}>
      <Routes>
        <Route path='/login' element={<UserLogin />} />
        <Route path='*' element={<AuthenticatedRoutes />} />
      </Routes>
    </StyledLineUpProps>
  )
}

export { AppContainer }
