import { ReactNode } from 'react'
// Components
import { Box } from '@line-up/line-up-props'
import { TopBar } from './TopBar'
// Utils
import { Organisation } from 'shared/interfaces'

export interface PageLayoutProps {
  children?: ReactNode
  organisations?: Organisation[]
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <Box fill>
      <TopBar gridArea='header' />
      <Box height='52px' />
      {children}
    </Box>
  )
}

export { PageLayout }
