import { useEffect, useState } from 'react'
// Components
import { Box, Button, Heading, Loading, Text } from '@line-up/line-up-props'
import { ScannerResultBottomBar } from './ScannerResultBottomBar'
import { Keyboard, StatusCritical, Alert, StatusGood } from 'grommet-icons'
// Services
import { useReedemBarcodeMutation } from 'services/admin'
// Utils
import { useNavigate, useParams } from 'react-router-dom'
import { useCurrentToken } from 'hooks'
// Types
import { ScanStatus } from 'shared/interfaces'

const resultColors = {
  WARNING: '#DB6A02',
  SUCCESS: '#78CF9C',
  FAILED: '#F44336'
}

interface ScannerResultCardProps {
  result?: ScanStatus
  redeemed?: boolean
  isError?: boolean
}

const ScannerResultCard = ({
  result,
  redeemed,
  isError
}: ScannerResultCardProps) => {
  const navigate = useNavigate()

  const handleEnterCodeManuallyClick = () => {
    navigate(`/scanner/manual`)
  }

  const failedContentCard = (
    <Box
      direction='column'
      margin='medium'
      background='white'
      round='small'
      align='center'
      alignContent='center'
      justify='center'
      pad={{
        horizontal: 'medium',
        vertical: 'large'
      }}
    >
      <Box direction='column' align='center' pad='medium' gap='small'>
        <Box direction='row' align='center' justify='center'>
          <StatusCritical size='25px' color='status-error' />
          <Heading
            margin='small'
            style={{
              marginTop: '0px'
            }}
            level={1}
            color='status-error'
            size='large'
          >
            Fail
          </Heading>
        </Box>
        <Text size='large' textAlign='center'>
          {isError
            ? 'Could not find a matching barcode with the details given.'
            : 'Barcode not recognised'}
        </Text>
      </Box>
      <Button
        primary
        size='large'
        label='Enter Barcode Manually'
        icon={<Keyboard color='light-1' size='large' />}
        onClick={handleEnterCodeManuallyClick}
      />
    </Box>
  )

  const warningContentCard = (
    <Box
      direction='column'
      margin='medium'
      background='white'
      round='small'
      align='center'
      pad={{
        horizontal: 'medium',
        vertical: 'large'
      }}
    >
      <Box direction='column' align='center' pad='medium' gap='small'>
        <Box direction='row' align='center'>
          <Alert size='25px' color={resultColors.WARNING} />
          <Heading
            margin='small'
            level={1}
            color={resultColors.WARNING}
            textAlign='center'
            size='large'
          >
            Warning
          </Heading>
        </Box>
        <Text size='large' textAlign='center'>
          Ticket valid but already redeemed.
        </Text>
      </Box>
    </Box>
  )

  const successContentCard = (
    <Box
      direction='column'
      margin='medium'
      background='white'
      round='small'
      align='center'
      pad={{
        horizontal: 'medium',
        vertical: 'large'
      }}
    >
      <Box direction='column' align='center' pad='medium' gap='small'>
        <Box direction='row' align='center'>
          <StatusGood size='25px' color='#4BA570' />
          <Heading margin='small' level={1} color='#4BA570' size='large'>
            Success - Ticket Redeemed
          </Heading>
        </Box>
        <Text size='large' textAlign='center'>
          Valid Ticket
        </Text>
      </Box>
    </Box>
  )

  if (redeemed && result === 'FAILED') {
    return warningContentCard
  }

  if (!result || isError) {
    return failedContentCard
  }

  switch (result) {
    case 'SUCCESS':
      return successContentCard
    case 'FAILED':
      return failedContentCard
  }
}

const ScannerResult = () => {
  const navigate = useNavigate()
  const { barcode } = useParams<{ barcode: string }>()
  const token = useCurrentToken()
  // Queries
  const [reedemBarcode, { data: barcodeData, isError: barcodeIsError }] =
    useReedemBarcodeMutation()

  // State
  const [result, setResult] = useState(barcodeData?.data?.scanStatus)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    token &&
      barcode &&
      reedemBarcode({
        barcode,
        token
      })
  }, [barcode, reedemBarcode, token])

  useEffect(() => {
    if (barcodeData) {
      setResult(barcodeData.data.scanStatus)
      setIsLoading(false)
    }
  }, [barcodeData])

  // Handlers
  const handleBottomBarClick = () => {
    navigate(-1)
  }

  if (barcodeIsError) {
    return (
      <Box
        direction='column'
        height='calc(100vh - 112px)'
        background={resultColors.FAILED}
      >
        <ScannerResultCard
          result={result}
          redeemed={barcodeData?.data.redeemed}
          isError={barcodeIsError}
        />
        <ScannerResultBottomBar onBottomBarClick={handleBottomBarClick} />
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box align='center' justify='center' height='100%'>
        <Loading />
      </Box>
    )
  }

  return (
    <Box
      direction='column'
      background={
        barcodeData?.data.redeemed && result === 'FAILED'
          ? resultColors.WARNING
          : resultColors.SUCCESS
      }
      height='calc(100vh - 112px)'
    >
      <ScannerResultCard
        result={result}
        redeemed={barcodeData?.data.redeemed}
      />
      <ScannerResultBottomBar onBottomBarClick={handleBottomBarClick} />
    </Box>
  )
}

export { ScannerResult }
