// Components
import { Box, Text } from '@line-up/line-up-props'
import { Keyboard } from 'grommet-icons'

type ScannerBottomBarProps = {
  onBottomBarClick: () => void
}

const ScannerBottomBar = ({ onBottomBarClick }: ScannerBottomBarProps) => {
  return (
    <Box
      onClick={() => onBottomBarClick()}
      style={{
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        right: '0px'
      }}
      height='60px'
      background='brand'
      direction='row'
      align='center'
      justify='center'
      gap='small'
    >
      <Keyboard size='large' color='white' />
      <Text color='white' weight={900}>
        Enter Barcode Manually
      </Text>
    </Box>
  )
}

export { ScannerBottomBar }
