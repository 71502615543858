import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'app/store'

export const lineupTransactionalApi = createApi({
  reducerPath: 'lineupTransactionalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LINE_UP_TRANSACTION_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['Event', 'Performance', 'Seating Plan', 'Transaction'],
  endpoints: builder => ({})
})
