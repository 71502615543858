import { lineupApi } from 'services'
import { User, Response, UserCreate, UserUpdate } from 'shared/interfaces'

const userApi = lineupApi.injectEndpoints({
  endpoints: builder => ({
    createUser: builder.mutation<Response<User>, UserCreate>({
      query: user => ({ url: `user/`, body: user, method: 'POST' }),
      invalidatesTags: ['User']
    }),
    editUser: builder.mutation<Response<User>, UserUpdate>({
      query: user => ({ url: `user/`, body: user, method: 'PUT' }),
      invalidatesTags: ['User']
    }),
    getUser: builder.query<Response<User>, void>({
      query: () => `user/`,
      providesTags: (result, error) => [{ type: 'User', id: 'main' }]
    })
  })
})

export const { useCreateUserMutation, useGetUserQuery, useEditUserMutation } =
  userApi
