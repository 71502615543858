import { lineupApi } from '../line-up'
import { ListResponse, Venue, Response } from 'shared/interfaces'

const VenueApi = lineupApi.injectEndpoints({
  endpoints: builder => ({
    getVenues: builder.query<
      ListResponse<Venue>,
      { page: number | void; size: number | void }
    >({
      query: ({ page = 1, size = 10 }) => `/venue/?page=${page}&size=${size}`,
      providesTags: ['Venue']
    }),
    getVenueById: builder.query<Response<Venue>, number>({
      query: venueId => `venue/${venueId}/`,
      providesTags: (result, error, id) => [{ type: 'Venue', id }]
    })
  })
})

export const { useGetVenuesQuery, useGetVenueByIdQuery } = VenueApi
