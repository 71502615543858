import { lineupTransactionalApi } from './base'
import { Event, ListTResponse } from 'shared/interfaces'

const eventApi = lineupTransactionalApi.injectEndpoints({
  endpoints: builder => ({
    fetchEventsT: builder.query<ListTResponse<Event>, void>({
      query: () => `event/`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Event' as const, id })),
              { type: 'Event', id: 'LIST' }
            ]
          : [{ type: 'Event', id: 'LIST' }]
    })
  })
})

export const { useFetchEventsTQuery } = eventApi
