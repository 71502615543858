import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from 'app/store'

type AuthState = {
  token: string | null
  organisationId: number | null
}

const slice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token'),
    organisationId: null
  } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { token } }: PayloadAction<{ token: string | null }>
    ) => {
      state.token = token
      token !== null
        ? localStorage.setItem('token', token)
        : localStorage.removeItem('token')
    },
    setOrganisationId: (
      state,
      {
        payload: { organisationId }
      }: PayloadAction<{ organisationId: number | null }>
    ) => {
      state.organisationId = organisationId
    }
  }
})

export const { setCredentials, setOrganisationId } = slice.actions

export default slice.reducer

export const selectCurrentToken = (state: RootState) => state.auth.token
export const selectCurrentOrganisationId = (state: RootState) =>
  state.auth.organisationId
