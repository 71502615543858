import { useContext } from 'react'
// Components
import {
  Box,
  Heading,
  ResponsiveContext,
  Spinner
} from '@line-up/line-up-props'
import { LineUpHelmet, ScannerDeviceForm } from 'components'
// Utils
import { useNavigate } from 'react-router-dom'

const ScannerDevice = () => {
  const size = useContext(ResponsiveContext)
  const navigate = useNavigate()

  // Handlers
  const handleSubmit = data => {
    navigate(`/scanner/${data.code}`)
  }

  return (
    <>
      <LineUpHelmet title='Manual Scanner' description='Manual Scanner' />
      <Box
        direction='column'
        margin={{ top: '1em' }}
        align='center'
        height='100%'
      >
        <Box margin='large'>
          <Spinner size='large' />
        </Box>
        <Heading level={1}>Scan with Barcode Reader</Heading>
        <Box width={size !== 'small' ? '500px' : '90%'}>
          <ScannerDeviceForm onSubmit={handleSubmit} />
        </Box>
      </Box>
    </>
  )
}
export { ScannerDevice }
